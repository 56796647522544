/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(85, 244, 82, 255),
    LETTER: new cc.Color(80, 60, 35, 255),
    COLOR_VICTORY: new cc.Color(240, 73, 0, 255),
    GREEN_YELLOW: new cc.Color(145, 255, 78, 255),
    PLAYER_ALARM_COLOR: new cc.Color(150, 150, 150, 255),
    COLOR_BROWN: new cc.Color(130, 70, 40, 255),
    PACKWINDOW_TITLE_YELLOW: new cc.Color(249, 205, 23, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    CONGRATE_STROKE: {
        color: cleverapps.styles.COLORS.GREEN,
        size: 4
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.GREEN_YELLOW,
        stroke: cleverapps.styles.DECORATORS.CONGRATE_STROKE
    },

    LETTERS_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    EXTRA_WINDOW_TITLE_TEXT: {
        size: 70,
        color: new cc.Color(245, 132, 46, 255),
        stroke: {
            size: 2,
            color: new cc.Color(63, 47, 25, 255)
        },
        shadow: {
            color: new cc.Color(63, 47, 25, 255),
            direction: cc.size(0, -2),
            blur: 1
        }
    },

    BOARD_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.IMAGE_FONT_SHADOW,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    PACKWINDOW_TITLE_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.PACKWINDOW_TITLE_YELLOW,
        shadow: {
            direction: cc.size(0, -6),
            blur: 1,
            color: new cc.Color(85, 40, 13, 255)
        },
        stroke: {
            size: 2,
            color: new cc.Color(81, 30, 6, 90)
        }
    },

    PACKWINDOW_LABEL_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE
    },

    LOADING_ANIMATION_TEXT: {
        size: 40,
        color: new cc.Color(0, 0, 70, 255)
    },

    CURRENT_TEXT: {
        size: 35,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

FingerView.PATH_BASE_ALPHA = 100;
FingerView.PATH_LINE_WIDTH = 20;

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    pattern: true
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    LevelId: {
        y: { align: "center", dy: -6 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    amount: {
        scale: 0.8
    }
});

cleverapps.overrideStyles(cleverapps.styles.Tabs, {
    margin: -10
});

cleverapps.overrideStyles(cleverapps.styles.Buttons, {
    margin: {
        x: 20
    }
});

cleverapps.overrideStyles(cleverapps.styles.ShuffleButton, {
    y: { align: "bottom", dy: -50 }
});

cleverapps.overrideStyles(cleverapps.styles.HintButtonBooster, {
    mirrorArrowY: true,
    zOrder: 1,
    arrowOffsetY: 34,

    phone: {
        scale: 0.8
    },

    width: 150,
    height: 120,
    content: {
        margin: -30,
        direction: cleverapps.UI.VERTICAL,
        layout: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 30 }
        }
    },

    position: [
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 16 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 510 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 510 } }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 180,
    height: 70,

    icon: {
        x: { align: "left", dx: -20 }
    },

    text: {
        y: { align: "center", dy: 2 }
    },

    position: [
        {
            x: { align: "left", dx: 58 },
            y: { align: "top", dy: -120 }
        },
        {
            x: { align: "left", dx: 58 },
            y: { align: "top", dy: -120 }
        },
        {
            x: { align: "left", dx: 58 },
            y: { align: "top", dy: -120 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 2.3
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    animation: {
        "default": {
            idleDelay: -0.7,
            dx: 0,
            dy: 50
        },

        "starterPack0": {
            idleAnimation: "idle1",
            introAnimation: "open1"
        },

        "starterPack": {
            idleAnimation: "idle2",
            introAnimation: "open2"
        },

        "starterPack2": {
            idleAnimation: "idle3",
            introAnimation: "open3"
        }
    },

    button: {
        width: 300,
        height: 120
    },

    rewards: {
        delay: 1.6,
        duration: 0.675,
        fitTo: {
            width: 120
        },

        positions: {
            "default": {
                "boosters": {
                    "13": {
                        x: 107,
                        y: -70
                    },
                    "14": {
                        x: 118,
                        y: 215
                    },
                    "15": {
                        x: -110,
                        y: -70
                    }
                },
                "hard": {
                    x: -132,
                    y: 215
                }
            }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LotteryIcon, {
    positions: {
        "piggy_bank_icon": {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 36 },
            scale: 0.75
        },
        "icon": {
            scale: 0.75
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.VkLotteryIcon, {
    positions: {
        "icon": {
            x: { align: "center", dx: -65 },
            y: { align: "bottom", dy: -5 },
            icon: 1.0
        },
        "bottom_right": {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -65 }
        },
        "bottom": {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -60 }
        }
    }
});

cleverapps.styles.xmasNewStyles = {
    heroTitleAnimation: {
        x: { align: "left", dx: -50 },
        y: { align: "top", dy: 270 },
        scale: 0.8
    }
};

cleverapps.overrideStyles(cleverapps.styles.CookieJarView, {
    scale: 0.5,
    prizeFlyPositionShift: {
        x: -50,
        y: -170
    }
});

cleverapps.overrideStyles(cleverapps.styles.CharView, {
    topPaddingWithBg: -8
});

cleverapps.overrideStyles(cleverapps.styles.LoadingAnimation, {
    text: false
});

cleverapps.overrideStyles(cleverapps.styles.SubscriptionTile, {
    title: {
        height: 80,
        y: -5,
        text: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 3 }
        }
    }
});

if (cleverapps.styles.CryptexWindow) {
    cleverapps.overrideStyles(cleverapps.styles.CryptexWindow, {
        margin: 10,
        padding: { x: 0, y: 0 }
    });
}
