/**
 * Created by vladislav on 18.03.2021
 */

var SlotMachineView = cc.Node.extend({
    ctor: function (slotMachine) {
        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.slotMachine = slotMachine;

        var styles = cleverapps.styles.SlotMachineView;

        var reelViews = this.slotMachine.reels.map(function (reel) {
            return new SlotMachineReelView(reel);
        }, this);

        var reelsLayout = new cleverapps.Layout(reelViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });
        this.addChild(reelsLayout);
        this.setContentSize2(reelsLayout.getContentSize());
        reelsLayout.setPositionRound(this.width / 2, this.height / 2);

        this.setScale(0.5);
        this.setPositionRound(styles);

        var arrow = new cc.Sprite(bundles.slot_machine.frames.arrow_png);
        this.addChild(arrow);
        arrow.setPositionRound(styles.arrow);

        this.amount = new SlotMachineTicketsAmount(this.slotMachine);
        this.addChild(this.amount);
        this.amount.setPositionRound(styles.amount);

        this.createHelpButton();

        this.createLights();

        this.slotMachine.on("giveReward", this.createListener(this.giveReward.bind(this)));
        this.slotMachine.on("spin", this.createListener(this.onSpin.bind(this)));
        this.slotMachine.on("showHelpForce", this.createListener(this.showHelpForce.bind(this)));
    },

    showHelpForce: function () {
        cleverapps.focusManager.display({
            focus: "SlotMachineHelpForce",
            action: function (f) {
                cleverapps.forces.create(this.helpButton, Forces.SLOT_MACHINE_HELP);
                cleverapps.forces.onceForceClosed = f;
            }.bind(this)
        });
    },

    createLights: function () {
        var styles = cleverapps.styles.SlotMachineView;

        this.lights = new cleverapps.Spine(bundles.slot_machine.jsons.lights_json);
        this.addChild(this.lights);
        this.lights.setPositionRound(styles.lights);
        this.lights.setAnimation(0, "idle", true);
    },

    createHelpButton: function () {
        this.helpButton = new cleverapps.UI.HelpButton(function () {
            if (cleverapps.forces.isRunningForce()) {
                cleverapps.forces.closeRunningForce();
            }

            cleverapps.focusManager.display({
                focus: "SlotMachineRulesWindow",
                action: function (f) {
                    new SlotMachineRulesWindow();
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }
            });
        });
        this.addChild(this.helpButton);
        this.helpButton.setPositionRound(cleverapps.styles.SlotMachineView.helpButton);
    },

    giveReward: function (prizeToGive, f) {
        if (this.spinSound) {
            cleverapps.audio.stopSound(this.spinSound);
            delete this.spinSound;
        }

        if (!prizeToGive) {
            this.showRewards(undefined, f);

            return;
        }

        var reward = prizeToGive.reward;

        var animation;
        if (reward.boosters) {
            animation = new cleverapps.Spine(bundles.slot_machine.jsons.reward_json);
            this.addChild(animation);
            animation.setPositionRound(this.width / 2, this.height / 2);
            animation.setSkin("booster_" + Object.keys(reward.boosters)[0]);
            animation.setAnimation(0, "show_up", false);

            cleverapps.audio.playSound(bundles.slot_machine.urls.boosters_reward_effect);
        } else {
            animation = new cleverapps.Spine(bundles.slot_machine.jsons.reward_gold_json);
            this.addChild(animation);
            animation.setPositionRound(this.width / 2, this.height / 2);
            animation.setAnimation(0, "animation2", false);

            cleverapps.audio.playSound(bundles.slot_machine.urls.coins_reward_effect);
        }

        animation.setCompleteListenerRemove(this.showRewards.bind(this, reward, f));

        var animationName = "reward";
        var areAllSame = prizeToGive.payline.every(function (type) {
            return type === prizeToGive.payline[0]; 
        });
        if (areAllSame && (prizeToGive.payline[0] === SlotMachine.COIN_SYMBOL.type || prizeToGive.payline[0] === SlotMachine.COINS_SYMBOL.type)) {
            animationName = "reward_big";
        }
        this.lights.setAnimation(0, animationName, true);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(2.7),
            new cc.CallFunc(function () {
                this.lights.setAnimation(0, "idle", true);
            }.bind(this))
        ));
    },

    createReward: function (amount, image) {
        var styles = cleverapps.styles.SlotMachineView;

        var text = cleverapps.UI.generateImageText(amount, cleverapps.styles.FONTS.SLOT_MACHINE_REWARD_TEXT);

        var icon = new cc.Sprite(image);

        return new cleverapps.Layout([icon, text], {
            margin: styles.rewards.textMargin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    showRewards: function (reward, f) {
        var styles = cleverapps.styles.SlotMachineView;

        var items = [];

        if (reward) {
            for (var key in reward) {
                if (key === "boosters") {
                    for (var booster in reward[key]) {
                        items.push(this.createReward("x" + reward[key][booster], RewardTypes.boosters.getSmallIcon({ id: booster })));
                    }
                } else {
                    items.push(this.createReward(reward[key], RewardTypes[key].getSmallIcon()));
                }
            }

            this.rewardsBlock = new cleverapps.Layout(items, {
                margin: styles.rewards.margin,
                direction: cleverapps.UI.HORIZONTAL
            });
        } else {
            this.rewardsBlock = cleverapps.UI.generateOnlyText("TryAgain", cleverapps.styles.FONTS.SLOT_MACHINE_REWARD_TEXT);
            this.rewardsBlock.fitTo(styles.tryAgainText.width, styles.tryAgainText.height);

            this.rewardsBlock.runAction(new cc.Sequence(
                new cc.DelayTime(2.0),
                new cc.CallFunc(this.removeRewardsBlock.bind(this))
            ));
        }

        var sound = reward ? bundles.slot_machine.urls.slotmachine_reward_effect : bundles.slot_machine.urls.no_reward_effect;

        this.rewardsBlock.setCascadeOpacityEnabledRecursively(true);
        this.addChild(this.rewardsBlock);
        this.rewardsBlock.setPositionRound(styles.rewards);
        this.rewardsBlock.setScale(0.5);
        this.rewardsBlock.setOpacity(0);
        this.rewardsBlock.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.ScaleTo(0.3, 1.0).easing(cc.easeBackInOut(1)),
                new cc.FadeIn(0.3)
            ),
            new cc.PlaySound(sound),
            new cc.CallFunc(f)
        ));
    },

    removeRewardsBlock: function () {
        if (this.rewardsBlock) {
            this.rewardsBlock.runAction(new cc.Sequence(
                new cc.FadeOut(0.3),
                new cc.RemoveSelf()
            ));
            delete this.rewardsBlock;
        }
    },

    onSpin: function () {
        this.removeRewardsBlock();

        this.lights.setAnimationAndIdleAfter("spin", "idle");

        this.spinSound = cleverapps.audio.playSound(bundles.slot_machine.urls.lights_effect);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SLOT_MACHINE_REWARD_TEXT: {
        size: 50,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },

    SLOT_MACHINE_BAR_TEXT: {
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.SlotMachineView = {
    x: { align: "center" },
    y: { align: "bottom", dy: -17 },

    helpButton: {
        x: { align: "center", dx: 188 },
        y: { align: "center", dy: 246 }
    },

    arrow: {
        x: { align: "center", dx: -239 },
        y: { align: "center", dy: 0 }
    },

    rewards: {
        textMargin: 4,
        margin: 5,
        x: { align: "center" },
        y: { align: "center", dy: 250 }
    },

    amount: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -160 }
    },

    buyButton: {
        x: { align: "right", dx: -40 },
        y: { align: "bottom", dy: -272 }
    },

    lights: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 250 }
    },

    tryAgainText: {
        width: 300,
        height: 65
    },

    margin: 10
};